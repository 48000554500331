// 操作记录
// popControlHistory
<template>

    <div class="popControlHistory">
        <el-dialog title="操作记录"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="504px"
                   :before-close="close">
            <ul class="controlList">
                <li class="controlItem"
                    v-for="(item,idx) in list"
                    :key="idx">
                    <div class="icon"></div>
                    <div class="info">
                        <!-- 操作人姓名 -->
                        <div class="name">{{item.username}}</div>
                        <!-- 操作内容 -->
                        <div class="details">{{item.content}}
                            <span class="reason green"
                                  v-if="item.remark && item.content === '审核通过'">审批意见：{{item.remark}}</span>
                            <span class="reason red"
                                  v-if="item.remark && item.content !== '审核通过'">驳回原因：{{item.remark}}</span>
                        </div>
                        <div class=""></div>
                    </div>
                    <!-- 操作时间 -->
                    <div class="time">{{item.occurTime}}</div>
                </li>
            </ul>
            <row v-if="list.length === 0">暂无数据</row>
        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/finance.js";
export default {
    name: "popControlHistory",

    props: {
        // 业务编码
        id: {
            type: String,
        },
        // 业务类型 如: 其他收入
        type: {
            type: String,
        },
    },

    components: {},

    data() {
        return {
            // 业务类型
            typeList: {
                资金调拨: "BANK_TREASURER",
                收入合同: "INCOME_CONTRACT",
                其他收入: "OTHER_INCOME",
                支出合同: "EXPENDITURE_CONTRACT",
                其他支出: "OTHER_EXPENDITURE",
                "交押金/保证金": "DEPOSIT",
                员工借支: "ADVANCES_EMPLOYEES",
                借出款: "LEND_COMPANY",
                预付款: "IMPREST",
                报销单: "EXPENSE_ACCOUNT",
                开票申请: "MAKE_INVOICE",
                工资: "WAGE",
                缴税费: "TAX",
                支出合同结算: "CONTRACT_SETTLEMENT",
                // TODO 需增加 增股减股
            },
            // 操作记录列表
            list: [],
            dialogVisible: true,
        };
    },

    created() {},

    mounted() {
        // 获取操作记录列表
        this.getList();
    },

    methods: {
        // 获取操作记录列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".controlList"),
            });
            API.getControlLog({
                code: this.id,
                type: this.typeList[this.type],
            })
                .then((res) => {
                    loading.close();
                    this.list = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    padding-left: 30px;
    height: 500px;
    overflow-y: auto;
}
.controlList {
    padding-left: 0;
    padding-right: 5px;
    .controlItem {
        position: relative;
        border-left: 2px solid #e2e4ea;
        padding-left: 25px;
        display: flex;
        .icon {
            width: 21px;
            height: 21px;
            background: url("../../../../assets/imgs/icon_greenDot.png");
            position: absolute;
            left: -10px;
            top: 0;
        }
        .info {
            flex: auto;
            .name {
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
                margin-bottom: 16px;
            }
            .details {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                margin-bottom: 30px;
                .reason {
                    margin-left: 10px;
                }
            }
        }
        .time {
            width: 135px;
            height: 11px;
            font-size: 14px;
            color: #999999;
            line-height: 24px;
        }
    }
    .controlItem:last-child {
        border-left: 0;
    }
}
</style>