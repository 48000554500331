// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const approvalApi = {
    /* 获取审批设置列表 */
    getApproveList(params) {
        return postJson("/approve/config/table", params);
    },

    /* 获取审批设置详情 */
    getApproveDetail(id, params) {
        return postJson(`/approve/config/step/list`, { configId: id, params });
    },

    /* 获取审批设置详情 */
    getApproveDetailForEdit(id) {
        return get(`/approve/config/step/config-list`, { configId: id });
    },

    /* 保存审批设置 */
    saveApproveDetail(params) {
        return postJson(`/approve/config/step/save`, params);
    },

    /* 保存停用审批设置 /停用状态 */
    saveApproveStop(params) {
        return postJson(`/approve/config/save-stop`, params);
    },

    /* ----------------------------- 审批中心 ---------------------------------- */
    // 审批中心列表
    approveTable(params) {
        return postJson("/approve/approve/table", params);
    },
    // 审批中心列表计数
    approveCount(params) {
        return get("/approve/approve/count", params);
    },
    // 审批中心列表计数-无权限版
    approveCountNoAuth(params) {
        return get("/approve/approve/count-no-auth", params);
    },
    // 获取审批环节信息
    getApproveDetails(id) {
        return get(`/approve/approve/findById/${id}`);
    },
    // 查询是否需要审批环节
    getNeedApply(type) {
        return get(`/approve/config/get-by-type/${type}`);
    },
    // 通过或驳回审批
    handleApply(params) {
        return postJson(`/approve/approve/approve`, params);
    },
    // 批量通过或驳回审批
    handleBatchApply(params) {
        return postJson(`/approve/approve/batchApprove`, params);
    },
    // 根据业务编码查询审批详情
    getApplyDetailsByCode(params) {
        return get(`/approve/approve/findByCode`, params);
    },
    // 获取是否显示驳回和通过按钮
    getApplyBtn(params) {
        return get(`/approve/approve/findBtnByCode`, params);
    },
    // 无审核环节时的驳回
    rejectApply(params) {
        return postJson(`/approve/approve/reject`, params);
    },
    // 审批条件
    getConditionApply(type) {
        return get(`/approve/config-condition-info/list/${type}`);
    },

};

export default approvalApi;