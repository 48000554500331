// 审批操作
// popHandleApply
<template>

    <div class="popHandleApply">
        <el-dialog :title="type"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="400px"
                   :before-close="close">
            <div class="handleApplyBox">
                <el-form :model="form"
                         ref="form"
                         :rules="formRules"
                         labelabel-position="top"
                         :inline="false">
                    <el-form-item label="审批意见"
                                  prop="auditDesc">
                        <el-input v-model="form.auditDesc"
                                  type="textarea"
                                  :rows="5"
                                  placeholder="审批意见"
                                  clearable></el-input>
                    </el-form-item>
                    <div class="footer">
                        <el-button type="danger"
                                   @click="close">取消</el-button>
                        <el-button type="primary"
                                   @click="save">保存</el-button>
                    </div>
                </el-form>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import { Loading } from "element-ui";
import API from "@/api/approval.js";
export default {
    name: "popHandleApply",

    props: {
        // 通过 还是 驳回
        type: {
            type: String,
            default: "通过",
        },
        // 操作的id
        id: {
            type: String,
            default: null,
        },
        // 批量操作的id集合
        idArr: {
            type: Array,
            default: null,
        },
        // 无审核环节时驳回所需参数
        // busCode 业务编码
        // type 类型，枚举【BANK_TREASURER('资金调拨'),INCOME_CONTRACT('收入合同'),OTHER_INCOME('其他收入'),EXPENDITURE_CONTRACT('支出合同'),OTHER_EXPENDITURE('其他支出'),DEPOSIT('交押金/保证金'),ADVANCES_EMPLOYEES('员工借支'),LEND_COMPANY('借出款'),IMPREST('预付款'),EXPENSE_ACCOUNT('报销单'),MAKE_INVOICE('开票申请'),WAGE('工资'),TAX('缴税费')】
        noApplyData: {
            type: Object,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,

            form: {
                auditDesc: "", // 审批意见
            },
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 操作审批
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.idArr) {
                        // 批量审批
                        this.applyBatch();
                    } else {
                        // 单个审批
                        this.applySingle();
                    }
                }
            });
        },
        // 批量审批
        applyBatch() {
            let loading = Loading.service({
                target: document.querySelector(".handleApplyBox"),
            });
            let data = Object.assign({}, this.form, {
                idList: this.idArr,
                method:
                    this.type === "通过" ? "AUDITED_PASS" : "AUDITED_REJECT",
            });
            API.handleBatchApply(data)
                .then(() => {
                    loading.close();
                    this.$message({
                        message: "操作成功！",
                        type: "success",
                    });
                    this.$emit("update");
                    this.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 单个审批
        applySingle() {
            let loading = Loading.service({
                target: document.querySelector(".handleApplyBox"),
            });
            if (this.id) {
                let data = Object.assign({}, this.form, {
                    id: this.id,
                    method:
                        this.type === "通过"
                            ? "AUDITED_PASS"
                            : "AUDITED_REJECT",
                });
                API.handleApply(data)
                    .then(() => {
                        loading.close();
                        this.$message({
                            message: "操作成功！",
                            type: "success",
                        });
                        this.$emit("update");
                        this.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            } else {
                let data = Object.assign(
                    {},
                    this.form,
                    {
                        method:
                            this.type === "通过"
                                ? "AUDITED_PASS"
                                : "AUDITED_REJECT",
                    },
                    this.noApplyData
                );
                API.rejectApply(data)
                    .then(() => {
                        loading.close();
                        this.$message({
                            message: "操作成功！",
                            type: "success",
                        });
                        this.$emit("update");
                        this.close();
                    })
                    .catch(() => {
                        loading.close();
                    });
            }
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {
        formRules() {
            let rule = null;
            if (this.type === "驳回") {
                rule = {
                    // 审批意见
                    auditDesc: {
                        required: true,
                        message: "请验证审批意见",
                        trigger: "blur",
                    },
                };
            }
            return rule;
        },
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>