// 审批信息
<template>
    <div class="comApplyInfo">
        <el-card :shadow="noCardBorder ? 'never' : 'always'">
            <div slot="header" v-if="!noCardBorder">
                <span>审批信息</span>
            </div>

            <!-- 审批信息 -->
            <div class="applyInfo">
                <template v-if="needApply">
                    <ul class="stepList" v-if="applyInfo">
                        <li
                            class="stepItem"
                            v-for="(item, idx) in applyStep"
                            :key="`stepItem${idx}${+new Date()}`"
                        >
                            <div class="iconBox">
                                <div
                                    class="icon"
                                    :class="{ send: item.typeName === '抄送' }"
                                ></div>
                                <template v-if="!item.idEdit">
                                    <div
                                        class="error"
                                        v-if="
                                            item.status.indexOf('驳回') !== -1
                                        "
                                    ></div>
                                    <div
                                        class="success"
                                        v-else-if="
                                            ['审核中', '未处理'].indexOf(
                                                item.status
                                            ) === -1
                                        "
                                    ></div>
                                </template>
                            </div>
                            <div class="info">
                                <p class="stepTitle">
                                    <span class="name"
                                        >{{ item.typeName }}
                                        <i
                                            class="tip noBg"
                                            v-if="item.currentStep"
                                            >（进行中）</i
                                        >
                                    </span>
                                    <template v-if="applyInfo.addabeCc">
                                        <el-link
                                            type="primary"
                                            :underline="false"
                                            @click="addStep(idx)"
                                            >添加环节</el-link
                                        >
                                        <!-- 此处只可以添加抄送环节 -->
                                    </template>
                                    <!-- <template v-else>
                                    <span class="time">{{item.dealTime}}</span>
                                </template> -->
                                </p>
                                <p class="personList">
                                    <template v-if="item.selectMember === 0">
                                        <el-select
                                            v-model="item.members[0].userId"
                                            placeholder="请选择"
                                            clearable
                                        >
                                            <el-option
                                                v-for="item in item.selectOpts"
                                                :key="item.code"
                                                :label="item.label"
                                                :value="item.code"
                                            >
                                            </el-option>
                                        </el-select>
                                    </template>
                                    <template v-else>
                                        <span
                                            v-for="(it, i) in item.members"
                                            :key="
                                                `stepItem${idx}member${i}${+new Date()}`
                                            "
                                            class="person"
                                            :class="{ delable: it.delable }"
                                            >{{ it.username }}
                                            <i
                                                class="el-icon-close"
                                                v-if="it.delable"
                                                @click="
                                                    delMember(
                                                        item.members,
                                                        i,
                                                        idx
                                                    )
                                                "
                                            ></i>
                                        </span>
                                        <p
                                            class="reason red"
                                            v-if="item.auditDesc"
                                        >
                                            {{ item.auditDesc }}
                                        </p>
                                        <el-button
                                            type="primary"
                                            han
                                            size="mini"
                                            v-if="
                                                isCanChangeApplyPerson &&
                                                    item.typeName === '抄送'
                                            "
                                            @click="choosePerson(idx)"
                                            >选择人员</el-button
                                        >
                                    </template>
                                </p>
                            </div>
                        </li>
                    </ul>
                </template>
                <div class="emptyBg" v-else>
                    <p>当前无审批环节</p>
                </div>
                <div class="footer">
                    <el-button
                        type="danger"
                        size="mini"
                        v-if="rejectFlag"
                        @click="openpopHandleApply('驳回')"
                        >驳回</el-button
                    >
                    <el-button
                        type="success"
                        size="mini"
                        v-if="auditFlag"
                        @click="openpopHandleApply('通过')"
                        >通过</el-button
                    >
                </div>

                <!-- TODO 操作记录 -->
                <pop-control-history
                    v-if="popControlHistoryVisible"
                    @close="closepopControlHistory"
                ></pop-control-history>

                <!-- 审批操作 -->
                <pop-handle-apply
                    v-if="popHandleApplyVisible"
                    :type="handleApplyType"
                    :id="(applyInfo && applyInfo.id) || null"
                    :noApplyData="{ busCode: this.id, type: this.applyType }"
                    @update="close"
                    @close="closepopHandleApply"
                ></pop-handle-apply>
            </div>
        </el-card>

        <!-- 添加成员
                 审批时单选
                 抄送时人员多选
                -->
        <pop-select-members
            v-if="selectMembersPersonVisible"
            :showCheckbox="true"
            :title="choosePersonTitle"
            @close="closeselectMembersPerson"
        ></pop-select-members>
    </div>
</template>

<script>
import PopHandleApply from "./popHandleApply";
import PopSelectMembers from "../../../../components/selectTree/popSelectMembers";
import { Loading } from "element-ui";
import API from "@/api/approval.js";
export default {
    name: "comApplyInfo",

    props: {
        // 查询详情所需code
        id: {
            type: String,
        },
        // 审批中心列表的id
        lisId: {
            type: String,
            default: null,
        },
        // 是否需要card的边框
        noCardBorder: {
            type: Boolean,
            default: false,
        },
        // 审批类型 - 查询是否需要审批
        applyType: {
            type: String,
        },
        /* 选择人员弹框title */
        choosePersonTitle: {
            type: String,
            default: "选择审批人",
        },
        // 是否是草稿
        status: {
            type: String,
            // default: "草稿",
        },
        // 是否可以修改审核环节的人 主要用于详情页，详情页不能修改环节
        canChangeApplyPerson: {
            type: Boolean,
            default: null,
        },
        // 查询条件审批流所需的表单数据
        formData: {
            type: Object,
            default: null,
        },
        // 是编辑还是详情
        editOrDetails: {
            type: String,
            details: "edit",
        },
        // publicKey 社保和工资模块后端需要
        publicKey: {
            type: String,
            default: null,
        },
    },

    components: {
        PopHandleApply,
        PopSelectMembers,
    },

    data() {
        return {
            applyInfo: null, // 查询的审批详情
            applyStep: [], // 审批环节
            needApply: false, // 是否需要审批
            // 是否可以修改审核环节的人
            isCanChangeApplyPerson: false,
            selectMembersPersonVisible: false, //选择人员弹框
            editPersonIdx: null, // 在更改的环节索引
            popHandleApplyVisible: false, // 审批操作
            rejectFlag: false, // 为true就显示驳回按钮
            auditFlag: false, // 为true就显示通过按钮
        };
    },

    created() {},

    mounted() {
        // 获取审批信息
        this.getApplyInfo();
    },

    methods: {
        // 获取审批信息
        getApplyInfo() {
            if (this.editOrDetails === "details") {
                /* 查询审核详情 */
                // 根据业务编码查询审批详情
                this.getApplyDetailsByCode();
                // 获取是否显示驳回和通过按钮
                this.getApplyBtn();
                return;
            }
            if (this.id && this.status !== "草稿" && this.status !== "驳回") {
                /* 查询审核详情 */
                // 根据业务编码查询审批详情
                this.getApplyDetailsByCode();
                // 获取是否显示驳回和通过按钮
                this.getApplyBtn();
            } else {
                /* 新增审核 */
                // 查询是否需要审批
                this.getNeedApply();
            }
        },
        // 查询是否需要审批
        getNeedApply() {
            let loading = Loading.service({
                target: document.querySelector(".comApplyInfo"),
            });
            API.getNeedApply(this.applyType)
                .then((res) => {
                    loading.close();
                    this.needApply = res.content.status !== 0 ? true : false;
                    this.$emit("isNeedApply", this.needApply);
                    this.needApply && this.getApproveDetails(res.content.id);
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取审批环节信息
        getApproveDetails(id) {
            let loading = Loading.service({
                target: document.querySelector(".comApplyInfo"),
            });
            let data = Object.assign({}, this.formData, {
                ...(this.publicKey && { publicKey: this.publicKey }),
            });
            API.getApproveDetail(id, data)
                .then((res) => {
                    loading.close();
                    this.applyInfo = res.content;
                    let applyStep = [];
                    res.content.configStepVoList.forEach((item) => {
                        let step = {
                            members: [],
                            sort: 0,
                            type: item.type,
                            typeName: item.stepName,
                            selectMember: item.selectMember,
                            userDefined: false,
                            currentStep: false,
                            idEdit: true, // 是否是编辑状态
                        };
                        if (item.selectMember === 1) {
                            item.relatedActionList.forEach((item) => {
                                step.members.push({
                                    userId: item.relateId,
                                    username: item.relateName,
                                    userDefined: false,
                                });
                            });
                        } else {
                            step.selectOpts = item.selectVos;
                            step.members.push({
                                userId: null,
                                userDefined: true,
                            });
                        }
                        applyStep.push(step);
                    });
                    this.applyStep = applyStep;
                    if (this.canChangeApplyPerson !== null) {
                        this.isCanChangeApplyPerson = this.canChangeApplyPerson;
                    } else {
                        this.isCanChangeApplyPerson =
                            res.content.addabeCc === 1 ? true : false;
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },

        // 根据业务编码查询审批详情
        getApplyDetailsByCode() {
            let loading = Loading.service({
                target: document.querySelector(".comApplyInfo"),
            });
            let data = {
                busCode: this.id,
                type: this.applyType,
                id: this.lisId, // 只有审批中心才有的id
            };
            API.getApplyDetailsByCode(data)
                .then((res) => {
                    loading.close();
                    if (res.content) {
                        this.needApply = true;
                        this.$emit("isNeedApply", true);
                        this.applyInfo = res.content;
                        let applyStep = [];
                        res.content.steps.forEach((item) => {
                            let step = {
                                members: [],
                                sort: 0,
                                status: item.status.text,
                                type: item.type.name,
                                typeName: item.type.text,
                                currentStep: item.currentStep,
                                auditDesc: item.auditDesc,
                                userDefined: false,
                            };
                            item.dealUserNames.forEach((it, idx) => {
                                step.members.push({
                                    userId: item.dealUserIds[idx],
                                    username: it,
                                });
                            });
                            applyStep.push(step);
                        });
                        this.applyStep = applyStep;
                        this.isCanChangeApplyPerson = false;
                        this.$emit("getApplyInfo", this.applyInfo);
                    }
                })
                .catch(() => {
                    loading.close();
                });
        },

        // 获取是否显示驳回和通过按钮
        getApplyBtn() {
            let loading = Loading.service({
                target: document.querySelector(".comApplyInfo"),
            });
            let data = {
                busCode: this.id,
                type: this.applyType,
            };
            API.getApplyBtn(data)
                .then((res) => {
                    loading.close();
                    this.rejectFlag = res.content.rejectFlag; // 为true就显示驳回按钮
                    this.auditFlag = res.content.auditFlag; // 为true就显示通过按钮
                })
                .catch(() => {
                    loading.close();
                });
        },

        /* 打开选择审批人弹框 */
        choosePerson(idx) {
            this.editPersonIdx = idx;
            this.selectMembersPersonVisible = true;
        },
        /* 选择审批人/抄送人关闭
        ids:选择人员id或id集合
        names:选择人员名称或名称集合
        persons:选择人员集合
        */
        closeselectMembersPerson(ids) {
            //抄送时 多选
            if (ids && JSON.stringify(ids) !== "{}") {
                let choosePerson = ids.choosePersonInfo.map((v) => {
                    return {
                        username: v.userName,
                        userId: v.id,
                        delable: true,
                    };
                });
                this.applyStep[this.editPersonIdx].members = [
                    ...this.applyStep[this.editPersonIdx].members,
                    ...choosePerson,
                ];
                this.editPersonIdx = null;
            }
            this.selectMembersPersonVisible = false;
        },
        /**
         * 删除抄送人员
         * list 要删除的数组
         * index 在数组中的索引
         * idx 父亲的索引，用于在所有成员都删除后，需要把这个环节删除
         */
        delMember(list, index, idx) {
            list.splice(index, 1);
            if (!list.length) {
                this.applyStep.splice(idx, 1);
            }
        },
        // 添加环节
        addStep(idx) {
            let data = {
                members: [],
                sort: 0,
                type: "DUPLICATE",
                typeName: "抄送",
                userDefined: true,
            };
            this.applyStep.splice(idx + 1, 0, data);
        },

        // 审批操作 打开弹框
        openpopHandleApply(type) {
            this.handleApplyType = type;
            this.popHandleApplyVisible = true;
        },
        // 审批操作 关闭弹框
        closepopHandleApply() {
            this.handleApplyType = null;
            this.popHandleApplyVisible = false;
            this.$emit("update");
            // 关闭弹窗
            this.close();
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
.applyInfo {
    background: #fbfdfe;
    border-radius: 0px 0px 10px 0px;
    position: relative;
    .title {
        height: 20px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 30px;
    }
    .stepList {
        padding: 0;
        padding-left: 13px;
        .stepItem:last-child {
            border-left: 0;
        }
        .stepItem {
            padding-bottom: 20px;
            border-left: 2px solid #e2e4ea;
            padding-left: 33px;
            position: relative;
            .iconBox {
                width: 28px;
                margin-right: 20px;
                position: absolute;
                left: -14px;
                .icon {
                    height: 28px;
                    background: url("../../../../assets/imgs/icon_apply.png")
                        no-repeat center center;
                    border-radius: 4px;
                }
                .icon.send {
                    background-image: url("../../../../assets/imgs/icon_send.png");
                }
                .error,
                .success {
                    width: 16px;
                    height: 16px;
                    background: #0bbf57
                        url("../../../../assets/imgs/icon_applySuccess.png")
                        no-repeat center center;
                    border: 2px solid #ffffff;
                    border-radius: 50%;
                    position: absolute;
                    right: -8px;
                    bottom: -8px;
                }
                .error {
                    background-image: url("../../../../assets/imgs/icon_applyError.png");
                }
            }
            .info {
                .stepTitle {
                    font-size: 16px;
                    color: #333333;
                    margin: 0;
                    .tip {
                        color: #f97b3d;
                        font-style: normal;
                    }
                    .time {
                        font-size: 14px;
                        color: #999999;
                        float: right;
                    }
                    .el-link {
                        float: right;
                        padding-left: 20px;
                        background: url("../../../../assets/imgs/icon_blueAdd.png")
                            no-repeat left center;
                    }
                }
                .personList {
                    .person {
                        display: inline-block;
                        height: 28px;
                        min-width: 62px;
                        box-sizing: border-box;
                        line-height: 28px;
                        background: #f7f8fb;
                        border: 1px solid #e1e4eb;
                        border-radius: 2px;
                        padding: 0 8px;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        text-align: center;
                        .el-icon-close {
                            color: #acb5bd;
                            margin-left: 15px;
                            cursor: pointer;
                        }
                        .el-icon-close:hover {
                            opacity: 0.7;
                            transition: all 0.5s;
                        }
                    }
                    .person.delable {
                        // width: 94px;
                    }
                }
            }
        }
    }
}
.footer {
    text-align: right;
}
.emptyBg {
    height: calc(100% - 30px);
    p {
        position: absolute;
        left: 50%;
        top: 52%;
        transform: translateX(-50%) translateY(-50%);
    }
}
.tip.noBg {
    background: none;
    border: 0;
}
</style>
