var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comApplyInfo"},[_c('el-card',{attrs:{"shadow":_vm.noCardBorder ? 'never' : 'always'}},[(!_vm.noCardBorder)?_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("审批信息")])]):_vm._e(),_c('div',{staticClass:"applyInfo"},[(_vm.needApply)?[(_vm.applyInfo)?_c('ul',{staticClass:"stepList"},_vm._l((_vm.applyStep),function(item,idx){return _c('li',{key:("stepItem" + idx + (+new Date())),staticClass:"stepItem"},[_c('div',{staticClass:"iconBox"},[_c('div',{staticClass:"icon",class:{ send: item.typeName === '抄送' }}),(!item.idEdit)?[(
                                        item.status.indexOf('驳回') !== -1
                                    )?_c('div',{staticClass:"error"}):(
                                        ['审核中', '未处理'].indexOf(
                                            item.status
                                        ) === -1
                                    )?_c('div',{staticClass:"success"}):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"info"},[_c('p',{staticClass:"stepTitle"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.typeName)+" "),(item.currentStep)?_c('i',{staticClass:"tip noBg"},[_vm._v("（进行中）")]):_vm._e()]),(_vm.applyInfo.addabeCc)?[_c('el-link',{attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.addStep(idx)}}},[_vm._v("添加环节")])]:_vm._e()],2),_c('p',{staticClass:"personList"},[(item.selectMember === 0)?[_c('el-select',{attrs:{"placeholder":"请选择","clearable":""},model:{value:(item.members[0].userId),callback:function ($$v) {_vm.$set(item.members[0], "userId", $$v)},expression:"item.members[0].userId"}},_vm._l((item.selectOpts),function(item){return _c('el-option',{key:item.code,attrs:{"label":item.label,"value":item.code}})}),1)]:[_vm._l((item.members),function(it,i){return _c('span',{key:("stepItem" + idx + "member" + i + (+new Date())),staticClass:"person",class:{ delable: it.delable }},[_vm._v(_vm._s(it.username)+" "),(it.delable)?_c('i',{staticClass:"el-icon-close",on:{"click":function($event){return _vm.delMember(
                                                    item.members,
                                                    i,
                                                    idx
                                                )}}}):_vm._e()])}),(item.auditDesc)?_c('p',{staticClass:"reason red"},[_vm._v(" "+_vm._s(item.auditDesc)+" ")]):_vm._e(),(
                                            _vm.isCanChangeApplyPerson &&
                                                item.typeName === '抄送'
                                        )?_c('el-button',{attrs:{"type":"primary","han":"","size":"mini"},on:{"click":function($event){return _vm.choosePerson(idx)}}},[_vm._v("选择人员")]):_vm._e()]],2)])])}),0):_vm._e()]:_c('div',{staticClass:"emptyBg"},[_c('p',[_vm._v("当前无审批环节")])]),_c('div',{staticClass:"footer"},[(_vm.rejectFlag)?_c('el-button',{attrs:{"type":"danger","size":"mini"},on:{"click":function($event){return _vm.openpopHandleApply('驳回')}}},[_vm._v("驳回")]):_vm._e(),(_vm.auditFlag)?_c('el-button',{attrs:{"type":"success","size":"mini"},on:{"click":function($event){return _vm.openpopHandleApply('通过')}}},[_vm._v("通过")]):_vm._e()],1),(_vm.popControlHistoryVisible)?_c('pop-control-history',{on:{"close":_vm.closepopControlHistory}}):_vm._e(),(_vm.popHandleApplyVisible)?_c('pop-handle-apply',{attrs:{"type":_vm.handleApplyType,"id":(_vm.applyInfo && _vm.applyInfo.id) || null,"noApplyData":{ busCode: this.id, type: this.applyType }},on:{"update":_vm.close,"close":_vm.closepopHandleApply}}):_vm._e()],2)]),(_vm.selectMembersPersonVisible)?_c('pop-select-members',{attrs:{"showCheckbox":true,"title":_vm.choosePersonTitle},on:{"close":_vm.closeselectMembersPerson}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }